import type { LoaderFunction } from '@remix-run/node'
import { json } from '@remix-run/node'

import { STATUS_TEXTS } from '~/res/constants/errors'
export { default as ErrorBoundary } from '../components/ErrorBoundary'

export const loader: LoaderFunction = () => {
  throw json(STATUS_TEXTS.PAGE_NOT_FOUND, { status: 404 })
}

export default function () {}
